html,
body, * {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    box-sizing: border-box;

}
::-webkit-scrollbar {
    width: 0px;
}

a {
    color: inherit;
    text-decoration: none;
}

.main-container{
    max-width: 1400px;
    margin: auto;
    width: 100%;

}
.layout{
    padding: 10px;
}

.navbar-container{
    display: flex;
    justify-content: space-between;
    margin: 6px 18px;
    position: relative;
}
.marquee-text{
    font-size: 29px;
    font-weight: 600;
    margin: 60px 0px;
    color: #f02d34;
}
.marquee {
    position: relative;
    height: 400px;
    width: 100%;
    overflow-x: hidden;
}

.track {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 15s linear infinite;
    width: 180%;
}
.track:hover {
    animation-play-state: paused;
}
@keyframes marquee {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
}

span.text-red {
    -webkit-text-stroke: 1px #f02d34;
    margin-left: 6px;
}
.logo{
    color: gray;
    font-size: 18px;

}
.cart-icon{
    font-size: 25px;
    color: gray;
    cursor: pointer;
    position: relative;
    transition: transform .4s ease;
    border: none;
    background-color: transparent;
}
.cart-icon:hover{
    transform: scale(1.1,1.1);
}
.cart-item-qty{
    position: absolute;
    right: -8px;
    font-size: 12px;
    color: #eee;
    background-color: #f02d34;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    text-align: center;
    font-weight: 600;

}
.products-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}
.product-card{
    cursor: pointer;
    margin: 1.5%;
    padding-bottom: 40px;
    transform: scale(1, 1);
    transition: transform 0.5s ease;
    color: #324d67;
}

.share{
    height:60px;
    padding: 50px;
    justify-content: center;
    border-radius: 15px;
    background-color: #ebebeb;
    transform: scale(1, 1);
    transition: transform 0.5s ease;
}
@media only screen and (min-width: 767px) {
    .show-large {
        display: block;
    }
    .show-mobile {
        display: none;
    }
}

.product-image{
    width:150px;
    border-radius: 15px;
    background-color: #ebebeb;
    transform: scale(1, 1);
    transition: transform 0.5s ease;
}
@media screen and (max-width: 700px) {
    .view {
        width:150px;
    }
}
@media screen and (min-width: 700px) {
    .view {
        width:300px;
    }
}


.look-image{
    border-radius: 15px;
    background-color: #ebebeb;
    transform: scale(1, 1);
    transition: transform 0.5s ease;
}
.look-name{
    font-weight: 500;
    width: 250px;
    overflow: hidden;

}
.template-look-name{
    font-weight: 500;
    width: 95%;
    overflow: hidden;

}

.category-image{
    width:250px;
    border-radius: 15px;
    background-color: #ebebeb;
    transform: scale(1, 1);
    transition: transform 0.5s ease;
}
.category-name{
    font-weight: 500;
    width: 270px;
    overflow: hidden;

}
.small-look-image{
    border-radius: 15px;
    background-color: #ebebeb;
    transform: scale(1, 1);
    transition: transform 0.5s ease;
}


.product-name{
    font-weight: 500;
    overflow: hidden;

}
@media screen and (max-width: 700px) {
    .view-product-name {
        width:150px;
        font-size: 12px;
    }
}
@media screen and (min-width: 700px) {
    .view-product-name {
        width:300px;
    }
}

.small-look-name{
    width:162px;
    font-weight: 500;
    font-size: 10px;
    overflow: hidden;

}

.credit{
    font-weight: 500;
    font-size: 10px;

    width: 270px;
    overflow: hidden;
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fff853 0%, #fad623 2%, #fc4a39 45%, #da27a3 60%,#285AEB 90%);
    vertical-align: middle;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.product-credit{
    font-weight: 500;
    font-size: 10px;
    width:162px;
    overflow: hidden;
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fff853 0%, #fad623 2%, #fc4a39 45%, #da27a3 60%,#285AEB 90%);
    vertical-align: middle;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.credit-small-look{
    font-weight: 500;
    font-size: 10px;
    width: 180px;

    overflow: hidden;
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fff853 0%, #fad623 2%, #fc4a39 45%, #da27a3 60%,#285AEB 90%);
    vertical-align: middle;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.product-price{
    font-weight: 800;
    font-size: 12px;

    margin-top: 6px;
    color: black;
}

.hero-banner-container{
    padding: 100px 40px;
    background-color: #dcdcdc;
    border-radius: 15px;
    position: relative;
    height: 500px;
    line-height: 0.9;
    width: 100%;

}
.hero-banner-container .beats-solo{
    font-size: 20px;
}
.hero-banner-container button{
    border-radius: 15px;
    padding: 10px 16px;
    background-color: #f02d34;
    color: white;
    border: none;
    margin-top: 40px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    z-index:10000 !important;
}

.hero-banner-container h3{
    font-size: 4rem;
    margin-top: 4px;
}
.hero-banner-container h1{
    color: white;
    font-size: 10em;
    margin-left: -20px;
    text-transform: uppercase;
}
.hero-banner-image{
    position: absolute;
    top: 0%;
    right:20%;
    width: 450px;
    height: 450px;
}


.desc{
    position: absolute;
    right: 10%;
    bottom: 5%;
    width: 300px;
    line-height: 1.3;
    display: flex;
    flex-direction: column;
    color: #324d67;

}
.desc p{
    color: #5f5f5f;
    font-weight: 100;
    text-align: end;
}

.filter-switch label {
    cursor: pointer;
}
.filter-switch-item input:checked + label {
    color: inherit;
}
.filter-switch-item input:not(:checked) + label {
    --bg-opacity: 0;
    box-shadow: none;
}

.desc h5{
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 16px;
    /* color: black; */
    align-self: flex-end;
}
.coperinno-style {
    font-size: 15px;
    margin: 15px;
    background: -webkit-linear-gradient(#324d67, #80e2ee);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.footer {
    position: sticky;
    width: 100%;
    background-color: rgb(255, 255, 255);
    color: white;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 15%);
    z-index:1021 !important;
    top: 0px;
  
}
   
.coperinno-style-title {
    font-size: 22px;
    background: -webkit-linear-gradient(#324d67, #80e2ee);
    margin: 20px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    
}
.title {
    -webkit-background-clip: text;
    text-align: center;
    -webkit-background-clip: text;
    margin-top: 10px;

    margin-bottom: 70px;


}

.follow-us{
    font-size: 14px;
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fff853 0%, #fad623 2%, #fc4a39 45%, #da27a3 60%,#285AEB 90%);
    margin: 40px;

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}


.share-look {
    font-size: 22px;
    background: -webkit-linear-gradient(#7300fd, #7169fc);
    margin: 20px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}
@media only screen and (min-width: 767px) {
    .show-large {
        display: block;
    }
    .show-mobile {
        display: none;
    }
}
.products-heading{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    color: #33526e;
}
.categories-bottons{
    backgroundColor: "#33526e",
}

.products-heading h2{
    font-size: 35px;
    font-weight: 200;
}
.products-heading p{
    font-size: 16px;
    font-weight: 200;
}
.footer-banner-container{
    padding: 100px 40px;
    background-color: #f02d34;
    border-radius: 15px;
    position: relative;
    height: 400px;
    line-height: 1;
    color: white;
    width: 100%;
    margin-top: 120px;
}
.product-container{
    margin-top: 15px;
    overflow: auto;
    max-height: 70vh;
    padding: 20px 10px;
}
.product{
    display: flex;
    gap: 30px;
    padding: 20px;
}
.product .cart-product-image{
    width:180px ;
    height: 150px;
    border-radius: 15px;
    background-color: #ebebeb;
}
.item-desc .flex{
    display: flex;
    justify-content: space-between;
    width: 350px;
    color: #324d67;

}
.item-desc .bottom{
    margin-top: 60px;
}
.flex h5{
    font-size: 24px;
}
.flex h4{
    font-size: 20px;
}
.total{
    display: flex;
    justify-content: space-between;
}
.total h3{
    font-size: 22px;

}

